import React from "react";
import { Layout } from "../components/Layout";
import { InputField } from "../components/InputField";
import { Button } from "../components/Button";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type DeleteForm = {
  email: string;
  confirmDelete: string;
};

const DeleteAccount = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<DeleteForm>();
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        //ts-ignore
        resolve("");
      }, 5000);
    });
  };

  const onSubmit = async ({ email, confirmDelete }: DeleteForm) => {
    setIsLoading(true);
    if (email !== "daveed@gmail.com") {
      setTimeout(() => {
        setError("email", {
          type: "manual",
          message: "Account not found",
        });
        setIsLoading(false);
      }, 3000);
      return;
    }
    if (confirmDelete.toLocaleLowerCase() !== "delete") {
      setError("confirmDelete", {
        type: "manual",
        message: "Please type DELETE",
      });
      setIsLoading(false);
      return;
    }
    await handleDeleteAccount();
    toast.success("Request has been sent successfully");
    reset();
    navigate("/");
  };

  return (
    <Layout>
      <p className="font-bold text-[20px]">Delete Account</p>
      <p className="text-sm">
        Deleting your account will remove all of your information from our
        database. This cannot be undone
      </p>

      <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          registration={{ ...register("email"), required: true }}
          type="email"
          isRequired
          placeholder="Email associated with your account"
          hasError={errors.email}
          errorMessage={errors.email?.message}
        />
        <p className="text-sm mt-5 mb-2">To confirm this type DELETE</p>
        <InputField
          registration={{ ...register("confirmDelete"), required: true }}
          isRequired
          hasError={errors.confirmDelete}
          errorMessage={errors.confirmDelete?.message}
        />
        <Button isLoading={isLoading} type="submit" className="mt-4 ml-auto">
          {isLoading ? "Deleting..." : "Delete Account"}
        </Button>
      </form>
    </Layout>
  );
};

export default DeleteAccount;
