
import FlipCountdown from "./Flipper/FlipCountdown";

const Countdown = () => {
  return (
    <div className="mt-16 sm:mt-0 sm:w-[70%] float-left border flex items-center rounded-2xl border-white countdown-wrapper py-7 lg:py-0">

      <FlipCountdown
        endAt={"2024-07-31 23:59:59"} // Date/Time
        size="large"
        hideYear
        hideMonth
        endAtZero
        theme='light'
        dayTitle='days'
        hourTitle='hours'
        minuteTitle='minutes'
        secondTitle='seconds'
      />
    </div>
  );
};

export default Countdown;
