import React from "react";

export const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <main className="overflow-hidden flex auth-bg py-7 h-screen flex-col  justify-center items-center	px-5">
      <section className="overflow-hidden pt-2 pb-5 lg:w-[35%] md:w-3/4 w-[95%] sm:px-5">
        {/* logo section */}
        <img
          src="/assets/logo.png"
          alt="street-business logo"
          className="md:cursor-pointer"
          height={21.65}
          width={188.53}
        />
        <div className="auth-form overflow-auto shadow-[5px_5px_0px_0px_#428ADE] md:shadow-[10px_10px_0px_0px_#428ADE] px-6 md:px-14 py-[35px]">
          {children}
        </div>
      </section>
    </main>
  );
};
