import Hero from "../components/Hero";
import Countdown from "../components/Countdown";
import JoinWaitlist from "../components/JoinWaitlist";
import Bridge from "../assets/ellipse-bridge.png";
import svg1 from '../assets/image-patterened-1.png';
import svg2 from '../assets/image-patterened-2.png';
import svg3 from '../assets/image-patterened-3.png';
import svg4 from '../assets/image-patterened-4.png';
import svg5 from '../assets/image-patterened-5.png';

const Home = () => {
  const preload_image = (im_url) =>{
    let img = new Image();
    img.src = im_url;
  }
  preload_image(svg1)
  preload_image(svg2)
  preload_image(svg3)
  preload_image(svg4)
  preload_image(svg5)
  
  return (
 
      <div className="w-[99vw] p-4 sm:p-6 block 2xl:w-[1536px] center-block">
        <div className="hero-bg-wrapper">
          <Hero />
        </div>
        <div className="w-full space-x-4 my-16 flex flex-col flex-col-reverse sm:flex-row items-stretch ">
          <Countdown />
          <div className="w-[21.350px] ml-0 flex items-center">
            <img className="hidden lg:flex" src={Bridge} alt="bridge" />
          </div>
          <JoinWaitlist />
        </div>
      </div>
  
  );
};

export default Home;
