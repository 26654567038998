import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import React from "react";
import clsx from "clsx";
import { Label } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

// import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

interface InputFieldProps {
  type?: "text" | "number" | "email" | "password";
  label?: string;
  className?: string;
  placeholder?: string;
  iconPosition?: "start" | "end";
  icon?: React.ReactNode;
  isDisabled?: boolean;
  hasError: FieldError | undefined;
  withIcon?: boolean;
  canPressSpace?: boolean;
  registration: Partial<UseFormRegisterReturn>;
  handleShowPassword?: () => void;
  min?: string;
  max?: string;
  value?: string | number;
  isRequired?: boolean;
  errorMessage?: string | undefined;
}

export const InputField: React.FC<InputFieldProps> = ({
  type = "text",
  icon,
  label,
  hasError,
  className,
  placeholder,
  registration,
  withIcon = false,
  iconPosition = "end",
  canPressSpace = true,
  handleShowPassword,
  min,
  max,
  isDisabled = false,
  isRequired,
  errorMessage,
}) => {
  // eslint-disable-next-line
  const handleKeyDown = (e: any) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const { name } = registration;

  return (
    <div>
      {!!label && (
        <Label htmlFor={name} isRequired={isRequired}>
          {label}
        </Label>
      )}
      <div className="relative">
        <input
          className={clsx(
            "h-12 w-full rounded-md border border-gray-150 bg-white bg-transparent px-4 outline-none placeholder:text-sm placeholder:text-gray-250 focus-within:border-secondary disabled:bg-gray-100",
            hasError && "border-red-500",
            className
          )}
          type={type}
          disabled={isDisabled}
          name={name}
          id={name}
          placeholder={placeholder}
          onKeyDown={canPressSpace ? undefined : handleKeyDown}
          min={min}
          max={max}
          {...registration}
        />
        {withIcon && (
          <span
            className={clsx(
              "absolute inset-y-0 flex items-center",
              iconPosition === "start" ? "left-0" : "right-0"
            )}
          >
            {handleShowPassword ? (
              <button
                type="button"
                onClick={handleShowPassword}
                title={type === "text" ? "Hide password" : "Show password"}
                className="focus:shadow-outline p-3 focus:outline-none"
              >
                {type === "password" ? (
                  <>
                    {/* <AiOutlineEyeInvisible /> */}
                    <span className="sr-only">Show password</span>
                  </>
                ) : (
                  <>
                    {/* <AiOutlineEye /> */}
                    <span className="sr-only">Hide password</span>
                  </>
                )}
              </button>
            ) : (
              <span className="p-1">{icon}</span>
            )}
          </span>
        )}
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};
