import * as React from "react";
import clsx from "clsx";
import { Spinner } from "./Spinner";

const variants = {
  primary:
    "bg-white text-black border-2 rounded-md border-black shadow-[4px_4px_0px_0px_#1b1b1b] hover:shadow-[-4px_4px_0px_0px_#1b1b1b] disabled:hover:shadow-[-4px_4px_0px_0px_#1b1b1b] disabled:hover:bg-gray-100",
  rounded:
    "bg-white text-black border-2 border-black shadow-[4px_4px_0px_0px_#1b1b1b] hover:shadow-[-4px_4px_0px_0px_#1b1b1b] disabled:hover:shadow-[-4px_4px_0px_0px_#1b1b1b] disabled:hover:bg-gray-100 rounded-full",
  danger:
    "bg-[#FD6B6A] text-black border-2 border-black shadow-[4px_4px_0px_0px_#1b1b1b] hover:shadow-[-4px_4px_0px_0px_#1b1b1b] disabled:hover:shadow-[-4px_4px_0px_0px_#1b1b1b] disabled:hover:bg-gray-100 rounded-md",
};

const sizes = {
  xs: "py-2 px-3 md:px-4 xl:px-5 text-[13px]",
  sm: "py-2 px-4 md:px-6 text-sm",
  md: "py-2 px-6 md:px-10 text-[15px]",
  lg: "py-3 px-8 lg:px-12 text-lg",
};

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = "button",
      className = "",
      variant = "primary",
      size = "md",
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          "flex items-center justify-center gap-5 py-[12.5px] sm:px-[38px] text-xs font-bold  transition duration-200 disabled:cursor-not-allowed disabled:opacity-70 md:text-sm",
          variants[variant],
          sizes[size],
          className
        )}
        {...props}
      >
        {isLoading && <Spinner size={size} className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = "Button";
