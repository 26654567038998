import NavBarPrivacy from "../components/NavBarPrivacy";

const PrivacyPolicy = () => {
  return (
    <div className="grid-bg">
      <NavBarPrivacy />
      <div className="md:container px-[10%] md:px-0 md:w-[60%]">
        <h3 className="header sora-subtitle pt-20">
          LAST UPDATED: JULY 19, 2023
        </h3>
        <h4 className="sora-heading">Privacy Policy for Street Services.</h4>
        <p className="sora-regular">
          Welcome to StreetApp! StreetApp is the platform where residents
          connect with the communities that hold significance to them. Our
          belief is that through fostering neighborly connections, we can foster
          a more compassionate world where every individual has a dependable
          local community they can count on.
        </p>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M45.0018 53.9167C45.7223 53.9167 46.4133 53.6305 46.9228 53.121C47.4323 52.6116 47.7185 51.9206 47.7185 51.2001C47.7185 50.4796 47.4323 49.7886 46.9228 49.2791C46.4133 48.7696 45.7223 48.4834 45.0018 48.4834C44.2813 48.4834 43.5903 48.7696 43.0808 49.2791C42.5714 49.7886 42.2852 50.4796 42.2852 51.2001C42.2852 51.9206 42.5714 52.6116 43.0808 53.121C43.5903 53.6305 44.2813 53.9167 45.0018 53.9167Z"
                fill="#EFC649"
              />
              <path
                d="M55.4673 40.8835V38.8002C55.4673 34.3002 54.384 28.3335 45.0007 28.3335C35.6173 28.3335 34.534 34.3002 34.534 38.8002V40.8835C29.8673 41.4668 28.334 43.8335 28.334 49.6502V52.7502C28.334 59.5835 30.4173 61.6668 37.2507 61.6668H52.7507C59.584 61.6668 61.6673 59.5835 61.6673 52.7502V49.6502C61.6673 43.8335 60.134 41.4668 55.4673 40.8835ZM45.0007 56.2335C42.2173 56.2335 39.9673 53.9668 39.9673 51.2002C39.9673 48.4168 42.234 46.1668 45.0007 46.1668C46.3342 46.1712 47.6119 46.7029 48.5549 47.6459C49.4979 48.5889 50.0296 49.8666 50.034 51.2002C50.034 53.9835 47.784 56.2335 45.0007 56.2335ZM37.2507 40.7335H36.8673V38.8002C36.8673 33.9168 38.2507 30.6668 45.0007 30.6668C51.7507 30.6668 53.134 33.9168 53.134 38.8002V40.7502H37.2507V40.7335Z"
                fill="#EFC649"
              />
            </svg>
          </div>
          StreetApp Privacy Practices.
        </div>
        <div className="sora-regular">
          We extend an invitation for you to review this Privacy Policy
          ("Privacy Policy"), which elucidates our approach to privacy and the
          manner in which we manage personal information obtained through
          StreetApp, as well as other social media platforms, our marketing
          endeavors, live events, and other activities outlined in this Privacy
          Policy. It further outlines how we utilize and disclose such
          information, while also providing you with options regarding your
          personal information.
        </div>

        <div className="sora-regular">
          The term "StreetApp" encompasses the StreetApp websites and our iOS
          and Android applications (referred to as our "Services").
          Additionally, we use "StreetApp," "we," "us," and "our" to refer to
          the entities operating our Services.
        </div>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M51.984 28.3335H38.0173C31.9507 28.3335 28.334 31.9502 28.334 38.0168V51.9668C28.334 58.0502 31.9507 61.6668 38.0173 61.6668H51.9673C58.034 61.6668 61.6507 58.0502 61.6507 51.9835V38.0168C61.6673 31.9502 58.0507 28.3335 51.984 28.3335ZM51.2507 51.2502H38.7507C38.0673 51.2502 37.5007 50.6835 37.5007 50.0002C37.5007 49.3168 38.0673 48.7502 38.7507 48.7502H51.2507C51.934 48.7502 52.5007 49.3168 52.5007 50.0002C52.5007 50.6835 51.934 51.2502 51.2507 51.2502ZM51.2507 41.2502H38.7507C38.0673 41.2502 37.5007 40.6835 37.5007 40.0002C37.5007 39.3168 38.0673 38.7502 38.7507 38.7502H51.2507C51.934 38.7502 52.5007 39.3168 52.5007 40.0002C52.5007 40.6835 51.934 41.2502 51.2507 41.2502Z"
                fill="#428ADE"
              />
            </svg>
          </div>
          Contents of this Privacy Policy.
        </div>

        <div className="sora-regular">
          <ol>
            <li>What Personal Information Is Collected?</li>
            <li>How Is My Personal Information Used?</li>
            <li>How Is My Personal Information Shared with Others?</li>
            <li>What Are My Choices?</li>
            <li>How Long Is My Personal Information Kept?</li>
            <li>Personal Information Relating to Children.</li>
            <li>Changes to this Privacy Policy.</li>
            <li>How to Contact Us.</li>
          </ol>
        </div>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M45.0018 53.9167C45.7223 53.9167 46.4133 53.6305 46.9228 53.121C47.4323 52.6116 47.7185 51.9206 47.7185 51.2001C47.7185 50.4796 47.4323 49.7886 46.9228 49.2791C46.4133 48.7696 45.7223 48.4834 45.0018 48.4834C44.2813 48.4834 43.5903 48.7696 43.0808 49.2791C42.5714 49.7886 42.2852 50.4796 42.2852 51.2001C42.2852 51.9206 42.5714 52.6116 43.0808 53.121C43.5903 53.6305 44.2813 53.9167 45.0018 53.9167Z"
                fill="#FD6B6A"
              />
              <path
                d="M55.4673 40.8835V38.8002C55.4673 34.3002 54.384 28.3335 45.0007 28.3335C35.6173 28.3335 34.534 34.3002 34.534 38.8002V40.8835C29.8673 41.4668 28.334 43.8335 28.334 49.6502V52.7502C28.334 59.5835 30.4173 61.6668 37.2507 61.6668H52.7507C59.584 61.6668 61.6673 59.5835 61.6673 52.7502V49.6502C61.6673 43.8335 60.134 41.4668 55.4673 40.8835ZM45.0007 56.2335C42.2173 56.2335 39.9673 53.9668 39.9673 51.2002C39.9673 48.4168 42.234 46.1668 45.0007 46.1668C46.3342 46.1712 47.6119 46.7029 48.5549 47.6459C49.4979 48.5889 50.0296 49.8666 50.034 51.2002C50.034 53.9835 47.784 56.2335 45.0007 56.2335ZM37.2507 40.7335H36.8673V38.8002C36.8673 33.9168 38.2507 30.6668 45.0007 30.6668C51.7507 30.6668 53.134 33.9168 53.134 38.8002V40.7502H37.2507V40.7335Z"
                fill="#FD6B6A"
              />
            </svg>
          </div>
          1. What Personal Information Is Collected?
        </div>

        <div className="sora-regular">
          We may obtain personal information directly from you, from other
          StreetApp members or users of our Services (referred to as "Members,"
          meaning individuals who have registered to use our Services), through
          technology that automatically gathers information from your browser or
          device, and from third-party sources. Please refer to the examples
          below. It's important to note that not all types of StreetApp accounts
          and features are available in every location, so some of the examples
          mentioned below may not be applicable in your area. Additionally,
          these examples are not exhaustive, and we may collect personal
          information that is not specifically mentioned but aligns with the
          principles outlined in this Privacy Policy. We may also provide
          additional information regarding our data collection or privacy
          practices in disclosures related to specific Services or
          circumstances, or in some cases, at the time we collect personal
          information from you. Rest assured that any personal information we
          collect will be utilized and processed in accordance with this Privacy
          Policy.
        </div>

        <div className="sora-heading-sm">
          1.1 Personal information we may collect from you.
        </div>

        <div className="sora-heading-xs">Your Personal Details</div>

        <div className="sora-regular">
          <ul>
            <li>
              When registering for StreetApp, you will be required to provide
              specific personal information, including your name, email address,
              and physical address. Additionally, you have the option to
              disclose additional personal details, either during the
              registration process or at a later stage, such as your gender,
              race or ethnicity, and demographic information. Moreover, you can
              choose to share information about yourself in your StreetApp
              profile or when engaging in posts or comments on StreetApp, which
              will be further explained below.
            </li>
            <li>
              If you wish to join StreetApp or utilize our Services before they
              are introduced in your neighborhood, you have the choice to
              provide us with your contact details and request to be notified
              once our Services become available in your area.
            </li>
            <li>
              In the event that you reach out to our support team, it is
              necessary to provide us with your contact information and a
              description of the issue at hand. Failure to do so would hinder
              our ability to respond to your inquiry or provide the necessary
              assistance.
            </li>
          </ul>
        </div>

        <div className="sora-heading-xs">Contact lists.</div>

        <div className="sora-regular">
          <ul>
            <li>
              You have the option to share or synchronize your contacts with
              StreetApp, which may include names, email addresses, physical
              addresses, phone numbers, or other related information stored
              within your contacts. Additionally, you can manually enter the
              contact details of your neighbors. The contacts you provide serve
              various purposes, such as assisting you in identifying which of
              your contacts have already joined StreetApp. This enables you to
              invite those who haven't joined yet, receive notifications when
              your contacts join, and personalize your experience by accessing
              more relevant information.
            </li>
          </ul>
        </div>

        <div className="sora-heading-xs">
          Profile, posts, interests, location, and recommendations.
        </div>

        <div className="sora-regular">
          <ul>
            <li>
              In your StreetApp profile, you have the ability to disclose
              various details about yourself, such as your interests, skills,
              pictures, information about your household, biographical
              information (such as your occupation, hometown, duration of
              residence in the area, etc.), emergency contact information, or
              aspects that you appreciate about your neighborhood.
            </li>
            <li>
              You are also allowed to create posts on StreetApp, which can
              include text, images, photos, videos, tags of individuals or
              places, polls, offers to sell or give away items, or any other
              content you wish to share.
            </li>
            <li>
              Furthermore, you have the option to share information related to
              your interests by joining and participating in a Private
              Community, or by following a specific neighborhood or topic.
            </li>
            <li>
              Additionally, you can contribute information about local
              businesses in various forms, such as recommendations, reviews,
              ratings, comments, or by claiming or creating a shop on StreetApp.
            </li>
          </ul>
        </div>

        <div className="sora-heading-xs">Payment Information.</div>

        <div className="sora-regular">
          <ul>
            <li>
              When you initiate a payment on StreetApp, either directly to or
              through our platform, you are supplying your payment card details
              and other necessary payment information to our third-party payment
              processors (for example, Paystack). This enables the completion of
              the transaction securely and efficiently.
            </li>
          </ul>
        </div>

        <div className="sora-heading-xs">Survey data.</div>

        <div className="sora-regular">
          <ul>
            <li>
              We may reach out to certain Members to invite their participation
              in surveys or other forms of general research. If you opt to take
              part in these initiatives, you may provide personal information
              either within your survey responses or along with them, as well as
              in any other feedback or comments you provide us.
            </li>
          </ul>
        </div>

        <div className="sora-heading-xs">Photos and videos.</div>

        <div className="sora-regular">
          <ul>
            <li>
              You have the option to grant permission for our Services to gather
              images, photos, videos, and other relevant information from your
              device's camera and photo library. For instance, when you decide
              to include visual content in your posts, these files may contain
              metadata that reveals details such as the method, time, location,
              creator, and format of the files. It's important to note that this
              metadata may be accessible to other Members or individuals who can
              access the files.
            </li>
          </ul>
        </div>

        <div className="sora-heading-xs">Geolocation.</div>

        <div className="sora-regular">
          <ul>
            <li>
              You have the option to provide consent for our Services to access
              the precise geolocation of your device. This permission enables us
              to confirm your address and enhances the operation and
              personalization of our Services. It facilitates features such as
              tagging posts, providing information or additions to a map, and
              assisting us in displaying more relevant content or advertisements
              tailored to your location.
            </li>
          </ul>
        </div>

        <div className="sora-heading-xs">Business information.</div>

        <div className="sora-regular">
          <ul>
            <li>
              When utilizing our Business Services, you may share information
              about your affiliation with a business, as well as the services
              provided by either yourself or the business itself.
            </li>
          </ul>
        </div>

        <div className="sora-heading-sm">
          1.2. Personal information we may collect about non-Members
        </div>

        <div className="sora-regular">
          Even if you do not use StreetApp, there is a possibility that your
          information may still be collected. Members of StreetApp have the
          option to share information about their contacts, including details
          sourced from contact lists, address books, or by manually entering
          your information. This contact information may encompass your name,
          email address, phone number, or other pertinent details about you.
          Additionally, StreetApp Members may post or comment with personal
          information concerning individuals who are not Members of the
          platform.
        </div>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M45.0018 53.9167C45.7223 53.9167 46.4133 53.6305 46.9228 53.121C47.4323 52.6116 47.7185 51.9206 47.7185 51.2001C47.7185 50.4796 47.4323 49.7886 46.9228 49.2791C46.4133 48.7696 45.7223 48.4834 45.0018 48.4834C44.2813 48.4834 43.5903 48.7696 43.0808 49.2791C42.5714 49.7886 42.2852 50.4796 42.2852 51.2001C42.2852 51.9206 42.5714 52.6116 43.0808 53.121C43.5903 53.6305 44.2813 53.9167 45.0018 53.9167Z"
                fill="#60B88E"
              />
              <path
                d="M55.4673 40.884V38.8007C55.4673 34.3007 54.384 28.334 45.0007 28.334C35.6173 28.334 34.534 34.3007 34.534 38.8007V40.884C29.8673 41.4673 28.334 43.834 28.334 49.6507V52.7507C28.334 59.584 30.4173 61.6673 37.2507 61.6673H52.7507C59.584 61.6673 61.6673 59.584 61.6673 52.7507V49.6507C61.6673 43.834 60.134 41.4673 55.4673 40.884ZM45.0007 56.234C42.2173 56.234 39.9673 53.9673 39.9673 51.2007C39.9673 48.4173 42.234 46.1673 45.0007 46.1673C46.3342 46.1717 47.6119 46.7034 48.5549 47.6464C49.4979 48.5894 50.0296 49.8671 50.034 51.2007C50.034 53.984 47.784 56.234 45.0007 56.234ZM37.2507 40.734H36.8673V38.8007C36.8673 33.9173 38.2507 30.6673 45.0007 30.6673C51.7507 30.6673 53.134 33.9173 53.134 38.8007V40.7507H37.2507V40.734Z"
                fill="#60B88E"
              />
            </svg>
          </div>
          2. How Is My Personal Information Used?
        </div>

        <div className="sora-regular">
          Personal information is utilized for the following objectives:
        </div>

        <div className="sora-heading-sm">
          2.1 Establish and develop communities.
        </div>

        <div className="sora-regular">
          We utilize personal information to identify and extend invitations to
          potential new Members for StreetApp. Additionally, we employ this
          information to establish new neighborhoods within StreetApp. For
          instance, you can request us to send invitations via email, SMS, or
          postal mail to potential new Members on your behalf, as well as
          reminders. We can also assist in printing materials for you to
          distribute, inviting your contacts and friends, or notifying you when
          our Services become available in your community.
        </div>

        <div className="sora-heading-sm">2.2 Set you up on StreetApp.</div>

        <div className="sora-regular">
          In order to initiate Member accounts on StreetApp, we employ personal
          information for the following purposes:
        </div>

        <div className="sora-regular">
          <ul>
            <li>
              Confirming Member accounts through various methods, which may
              include cross-referencing with third-party data and public
              records, utilizing verification codes, or employing
              geo-verification techniques.
            </li>
            <li>
              Populate Member profiles and customize the StreetApp experience
              for each individual.
            </li>
            <li>Notify neighbors when new Members join their community.</li>
            <li>
              Develop and provide Business Services that enable Members to
              discover and engage with local businesses in their communities.
            </li>
          </ul>
        </div>

        <div className="sora-heading-sm">2.3 Research & Development.</div>

        <div className="sora-regular">
          For StreetApp, we leverage personal information for research and
          development endeavors. As part of these activities, we may generate
          aggregated, de-identified, or anonymized data from the personal
          information we collect. By eliminating any information that could
          personally identify you or others, we create anonymous data. This
          anonymous data may be utilized by us, as well as shared with third
          parties, for legitimate business purposes. These purposes include
          analyzing and enhancing our Services, as well as promoting StreetApp.
        </div>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M45.0018 53.9167C45.7223 53.9167 46.4133 53.6305 46.9228 53.121C47.4323 52.6116 47.7185 51.9206 47.7185 51.2001C47.7185 50.4796 47.4323 49.7886 46.9228 49.2791C46.4133 48.7696 45.7223 48.4834 45.0018 48.4834C44.2813 48.4834 43.5903 48.7696 43.0808 49.2791C42.5714 49.7886 42.2852 50.4796 42.2852 51.2001C42.2852 51.9206 42.5714 52.6116 43.0808 53.121C43.5903 53.6305 44.2813 53.9167 45.0018 53.9167Z"
                fill="#EFC649"
              />
              <path
                d="M55.4673 40.884V38.8007C55.4673 34.3007 54.384 28.334 45.0007 28.334C35.6173 28.334 34.534 34.3007 34.534 38.8007V40.884C29.8673 41.4673 28.334 43.834 28.334 49.6507V52.7507C28.334 59.584 30.4173 61.6673 37.2507 61.6673H52.7507C59.584 61.6673 61.6673 59.584 61.6673 52.7507V49.6507C61.6673 43.834 60.134 41.4673 55.4673 40.884ZM45.0007 56.234C42.2173 56.234 39.9673 53.9673 39.9673 51.2007C39.9673 48.4173 42.234 46.1673 45.0007 46.1673C46.3342 46.1717 47.6119 46.7034 48.5549 47.6464C49.4979 48.5894 50.0296 49.8671 50.034 51.2007C50.034 53.984 47.784 56.234 45.0007 56.234ZM37.2507 40.734H36.8673V38.8007C36.8673 33.9173 38.2507 30.6673 45.0007 30.6673C51.7507 30.6673 53.134 33.9173 53.134 38.8007V40.7507H37.2507V40.734Z"
                fill="#EFC649"
              />
            </svg>
          </div>
          3. How Is My Personal Information Shared with Others?
        </div>

        <div className="sora-regular">
          The sharing of personal information occurs in the following ways:
        </div>

        <div className="sora-heading-sm">
          3.1 Content you share on StreetApp.
        </div>

        <div className="sora-regular">
          StreetApp provides a platform for neighbors to exchange useful
          information. Accordingly, any content you share on StreetApp,
          including posts, replies, recommendations, messages, and profile
          details, may be viewable by other StreetApp members, businesses,
          government agencies, or the general public.
        </div>

        <div className="sora-heading-sm">3.2 Account confirmation.</div>

        <div className="sora-regular">
          We might disclose your personal information to our verification
          partners, including telecommunications providers (if you opt for
          confirming your account via your telephone number) or other data
          providers, in an effort to ensure your identity matches your claimed
          credentials
        </div>

        <div className="sora-heading-sm">3.3 Legal and safety reasons.</div>

        <div className="sora-regular">
          We may store, safeguard, or disclose your Content and personal
          information to law enforcement, government authorities, or relevant
          parties when we have a genuine belief that such action is reasonably
          necessary to: (a) comply with applicable laws and respond to valid
          legal requests (e.g., subpoenas, search warrants, court orders, or
          government or law enforcement inquiries); (b) investigate, prevent,
          and address fraud, unauthorized or unlawful activities, security
          concerns, or technical issues; (c) safeguard our rights, property, or
          safety, as well as the rights, property, or safety of others.
        </div>

        <div className="sora-heading-sm">3.4 Advertising.</div>

        <div className="sora-regular">
          When using StreetApp, if you interact with an advertisement or respond
          to an offer, we may share your response and limited profile
          information (potentially containing personal details) with the
          advertiser. Additionally, we may track and share your engagement with
          online ads to assess their effectiveness.
        </div>

        <div className="sora-regular">
          To support our Services, including StreetApp, we rely on advertising,
          which includes third-party advertising networks. With your consent,
          our advertising partners utilize cookies and similar technologies to
          gather information about our users and their devices over time, both
          within StreetApp and across other online platforms. In compliance with
          applicable laws, we may share specific personal information, such as
          hashed emails and advertising identifiers, with our advertising
          partners. This information is used to deliver targeted online
          advertising to you or similar audiences. Our advertising partners may
          link this data with other personal information they possess to tailor
          personalized ads on StreetApp and other websites or services while
          also evaluating the ad campaigns' effectiveness.
        </div>

        <div className="sora-heading-sm">3.5 Social media.</div>

        <div className="sora-regular">
          Our Services offer various social media features, such as a social
          media share button. If you decide to use this button to share Content
          containing personal information, the information related to that
          Content will be shared with the chosen social media platform.
        </div>

        <div className="sora-heading-sm">3.6 Professional advisors.</div>

        <div className="sora-regular">
          At StreetApp, we may disclose personal information to our professional
          advisors, including lawyers, auditors, bankers, and insurers, as
          required during the provision of their professional services to
          StreetApp.
        </div>

        <div className="sora-heading-sm">3.7 Payment processors.</div>

        <div className="sora-regular">
          When you make a purchase on our Services using any payment card
          information, the data is directly collected and processed by our
          payment processors, such as Paystack. Please note that any payment
          information you provide to Paystack is subject to their privacy
          policy, available at https://paystack.com/terms.
        </div>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M45.0018 53.9167C45.7223 53.9167 46.4133 53.6305 46.9228 53.121C47.4323 52.6116 47.7185 51.9206 47.7185 51.2001C47.7185 50.4796 47.4323 49.7886 46.9228 49.2791C46.4133 48.7696 45.7223 48.4834 45.0018 48.4834C44.2813 48.4834 43.5903 48.7696 43.0808 49.2791C42.5714 49.7886 42.2852 50.4796 42.2852 51.2001C42.2852 51.9206 42.5714 52.6116 43.0808 53.121C43.5903 53.6305 44.2813 53.9167 45.0018 53.9167Z"
                fill="#428ADE"
              />
              <path
                d="M55.4673 40.884V38.8007C55.4673 34.3007 54.384 28.334 45.0007 28.334C35.6173 28.334 34.534 34.3007 34.534 38.8007V40.884C29.8673 41.4673 28.334 43.834 28.334 49.6507V52.7507C28.334 59.584 30.4173 61.6673 37.2507 61.6673H52.7507C59.584 61.6673 61.6673 59.584 61.6673 52.7507V49.6507C61.6673 43.834 60.134 41.4673 55.4673 40.884ZM45.0007 56.234C42.2173 56.234 39.9673 53.9673 39.9673 51.2007C39.9673 48.4173 42.234 46.1673 45.0007 46.1673C46.3342 46.1717 47.6119 46.7034 48.5549 47.6464C49.4979 48.5894 50.0296 49.8671 50.034 51.2007C50.034 53.984 47.784 56.234 45.0007 56.234ZM37.2507 40.734H36.8673V38.8007C36.8673 33.9173 38.2507 30.6673 45.0007 30.6673C51.7507 30.6673 53.134 33.9173 53.134 38.8007V40.7507H37.2507V40.734Z"
                fill="#428ADE"
              />
            </svg>
          </div>
          4. What are my choices?
        </div>

        <div className="sora-regular">
          In the settings of your account, you have the option to determine the
          extent to which your personal information, such as your full name, is
          shared on your profile and alongside your posts. These account
          settings are adjustable at your convenience.
        </div>

        <div className="sora-regular">
          Additionally, when you make a post on StreetApp, you have the control
          to choose the specific audience who can view your post.
        </div>

        <div className="sora-regular">
          If you prefer to opt out of personalized advertising from third
          parties, you can do so by modifying your cookie settings or adjusting
          the privacy settings on your mobile device.
        </div>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M45.0018 53.9167C45.7223 53.9167 46.4133 53.6305 46.9228 53.121C47.4323 52.6116 47.7185 51.9206 47.7185 51.2001C47.7185 50.4796 47.4323 49.7886 46.9228 49.2791C46.4133 48.7696 45.7223 48.4834 45.0018 48.4834C44.2813 48.4834 43.5903 48.7696 43.0808 49.2791C42.5714 49.7886 42.2852 50.4796 42.2852 51.2001C42.2852 51.9206 42.5714 52.6116 43.0808 53.121C43.5903 53.6305 44.2813 53.9167 45.0018 53.9167Z"
                fill="#FFD387"
              />
              <path
                d="M55.4673 40.884V38.8007C55.4673 34.3007 54.384 28.334 45.0007 28.334C35.6173 28.334 34.534 34.3007 34.534 38.8007V40.884C29.8673 41.4673 28.334 43.834 28.334 49.6507V52.7507C28.334 59.584 30.4173 61.6673 37.2507 61.6673H52.7507C59.584 61.6673 61.6673 59.584 61.6673 52.7507V49.6507C61.6673 43.834 60.134 41.4673 55.4673 40.884ZM45.0007 56.234C42.2173 56.234 39.9673 53.9673 39.9673 51.2007C39.9673 48.4173 42.234 46.1673 45.0007 46.1673C46.3342 46.1717 47.6119 46.7034 48.5549 47.6464C49.4979 48.5894 50.0296 49.8671 50.034 51.2007C50.034 53.984 47.784 56.234 45.0007 56.234ZM37.2507 40.734H36.8673V38.8007C36.8673 33.9173 38.2507 30.6673 45.0007 30.6673C51.7507 30.6673 53.134 33.9173 53.134 38.8007V40.7507H37.2507V40.734Z"
                fill="#FFD387"
              />
            </svg>
          </div>
          5. How long is my information kept?
        </div>

        <div className="sora-regular">
          We keep personal information stored in our server logs, databases, and
          records for as long as it is required to offer our Services. In
          certain cases, we may need to retain some of your information for an
          extended period, such as to meet our legal or regulatory requirements,
          handle disputes, or safeguard against legal claims.
        </div>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M45.0018 53.9167C45.7223 53.9167 46.4133 53.6305 46.9228 53.121C47.4323 52.6116 47.7185 51.9206 47.7185 51.2001C47.7185 50.4796 47.4323 49.7886 46.9228 49.2791C46.4133 48.7696 45.7223 48.4834 45.0018 48.4834C44.2813 48.4834 43.5903 48.7696 43.0808 49.2791C42.5714 49.7886 42.2852 50.4796 42.2852 51.2001C42.2852 51.9206 42.5714 52.6116 43.0808 53.121C43.5903 53.6305 44.2813 53.9167 45.0018 53.9167Z"
                fill="#AF58A7"
              />
              <path
                d="M55.4673 40.884V38.8007C55.4673 34.3007 54.384 28.334 45.0007 28.334C35.6173 28.334 34.534 34.3007 34.534 38.8007V40.884C29.8673 41.4673 28.334 43.834 28.334 49.6507V52.7507C28.334 59.584 30.4173 61.6673 37.2507 61.6673H52.7507C59.584 61.6673 61.6673 59.584 61.6673 52.7507V49.6507C61.6673 43.834 60.134 41.4673 55.4673 40.884ZM45.0007 56.234C42.2173 56.234 39.9673 53.9673 39.9673 51.2007C39.9673 48.4173 42.234 46.1673 45.0007 46.1673C46.3342 46.1717 47.6119 46.7034 48.5549 47.6464C49.4979 48.5894 50.0296 49.8671 50.034 51.2007C50.034 53.984 47.784 56.234 45.0007 56.234ZM37.2507 40.734H36.8673V38.8007C36.8673 33.9173 38.2507 30.6673 45.0007 30.6673C51.7507 30.6673 53.134 33.9173 53.134 38.8007V40.7507H37.2507V40.734Z"
                fill="#AF58A7"
              />
            </svg>
          </div>
          6. Personal Information Relating to Children?
        </div>

        <div className="sora-regular">
          Our Services cannot be accessed by children under 13 years old or
          individuals who are not old enough for StreetApp to handle their
          personal information without parental or age-specific consent in their
          place of residence. If you suspect that we may have inadvertently
          collected personal information from a child falling into either of
          these categories, please reach out to us at privacy@streetapp.io so we
          can promptly investigate and delete the information, as required.
        </div>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M45.0018 53.9167C45.7223 53.9167 46.4133 53.6305 46.9228 53.121C47.4323 52.6116 47.7185 51.9206 47.7185 51.2001C47.7185 50.4796 47.4323 49.7886 46.9228 49.2791C46.4133 48.7696 45.7223 48.4834 45.0018 48.4834C44.2813 48.4834 43.5903 48.7696 43.0808 49.2791C42.5714 49.7886 42.2852 50.4796 42.2852 51.2001C42.2852 51.9206 42.5714 52.6116 43.0808 53.121C43.5903 53.6305 44.2813 53.9167 45.0018 53.9167Z"
                fill="#FFFFFF"
              />
              <path
                d="M55.4673 40.884V38.8007C55.4673 34.3007 54.384 28.334 45.0007 28.334C35.6173 28.334 34.534 34.3007 34.534 38.8007V40.884C29.8673 41.4673 28.334 43.834 28.334 49.6507V52.7507C28.334 59.584 30.4173 61.6673 37.2507 61.6673H52.7507C59.584 61.6673 61.6673 59.584 61.6673 52.7507V49.6507C61.6673 43.834 60.134 41.4673 55.4673 40.884ZM45.0007 56.234C42.2173 56.234 39.9673 53.9673 39.9673 51.2007C39.9673 48.4173 42.234 46.1673 45.0007 46.1673C46.3342 46.1717 47.6119 46.7034 48.5549 47.6464C49.4979 48.5894 50.0296 49.8671 50.034 51.2007C50.034 53.984 47.784 56.234 45.0007 56.234ZM37.2507 40.734H36.8673V38.8007C36.8673 33.9173 38.2507 30.6673 45.0007 30.6673C51.7507 30.6673 53.134 33.9173 53.134 38.8007V40.7507H37.2507V40.734Z"
                fill="#FFFFFF"
              />
            </svg>
          </div>
          7. Changes to this Privacy Policy.
        </div>

        <div className="sora-regular">
          We reserve the right to update this Privacy Policy periodically, so we
          encourage you to check it regularly. In the event of significant
          changes to this Privacy Policy, we will take necessary measures to
          inform you in advance, as required by applicable law. This may include
          sending you an email or posting a notice in your neighborhood feed. If
          you disagree with any of the changes, you have the option to delete
          your account. By continuing to use our Services after we publish or
          send a notice about the updated Privacy Policy, you acknowledge that
          your personal information will be subject to the revised Privacy
          Policy, effective as of its stated date.
        </div>

        <div className="sora-heading-md">
          <div className="pb-5">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="89"
                height="89"
                rx="44.5"
                fill="#3D3D3D"
                stroke="#8F8F8F"
              />
              <path
                d="M45.0018 53.9167C45.7223 53.9167 46.4133 53.6305 46.9228 53.121C47.4323 52.6116 47.7185 51.9206 47.7185 51.2001C47.7185 50.4796 47.4323 49.7886 46.9228 49.2791C46.4133 48.7696 45.7223 48.4834 45.0018 48.4834C44.2813 48.4834 43.5903 48.7696 43.0808 49.2791C42.5714 49.7886 42.2852 50.4796 42.2852 51.2001C42.2852 51.9206 42.5714 52.6116 43.0808 53.121C43.5903 53.6305 44.2813 53.9167 45.0018 53.9167Z"
                fill="#AC5151"
              />
              <path
                d="M55.4673 40.884V38.8007C55.4673 34.3007 54.384 28.334 45.0007 28.334C35.6173 28.334 34.534 34.3007 34.534 38.8007V40.884C29.8673 41.4673 28.334 43.834 28.334 49.6507V52.7507C28.334 59.584 30.4173 61.6673 37.2507 61.6673H52.7507C59.584 61.6673 61.6673 59.584 61.6673 52.7507V49.6507C61.6673 43.834 60.134 41.4673 55.4673 40.884ZM45.0007 56.234C42.2173 56.234 39.9673 53.9673 39.9673 51.2007C39.9673 48.4173 42.234 46.1673 45.0007 46.1673C46.3342 46.1717 47.6119 46.7034 48.5549 47.6464C49.4979 48.5894 50.0296 49.8671 50.034 51.2007C50.034 53.984 47.784 56.234 45.0007 56.234ZM37.2507 40.734H36.8673V38.8007C36.8673 33.9173 38.2507 30.6673 45.0007 30.6673C51.7507 30.6673 53.134 33.9173 53.134 38.8007V40.7507H37.2507V40.734Z"
                fill="#AC5151"
              />
            </svg>
          </div>
          8. How to Contact Us.
        </div>

        <div className="sora-regular">
          If you have any questions relating to this Privacy Policy or would
          like to find out more about exercising your data protection rights,
          please reach out to us via email at privacy@streetapp.io.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
