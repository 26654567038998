import PlayStoreLogoMobile from "../assets/play-store-mobile-hero.svg";
import AppStoreLogoMobile from "../assets/app-store-mobile-hero.svg";
import PlayStoreLogo from "../assets/play-store.svg";
import AppStoreLogo from "../assets/app-store.svg";
import Phones from "../assets/mockup.png";
import Podium from "../assets/podium.png";
import NavBar from "./NavBar";

const Hero = () => {
  return (
    <>
      <div className="hero-bg-svg sm:animate-color-loop rounded-3xl overflow-hidden">
        <NavBar />
        <div className="hidden sm:flex text-white">
          <div className="hero-content flex flex-row justify-between">
            <div className="w-3/5 float-left flex flex-col self-center p-10">
              <h1 className="line-height font-gimme-danger sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-6xl">
                OWN YOUR STREET, GET STREET APP
              </h1>

              <p className="mt-4">
                Join Our Digital Hub: Where Neighbors Become Friends, Ideas
                Flourish, and <br /> Stories Unfold! Coming Soon!
              </p>
              <div className="flex flex-inline space-x-4 mt-8">
                <img src={PlayStoreLogo} alt="" />
                <img src={AppStoreLogo} alt="" />
              </div>
            </div>
            <div className="w-2/5 items-center flex flex-col phone-section pr-3">
              <div className="z-10">
                <img className="phones" src={Phones} alt="phones" />
              </div>
              <div className="podium mt-4">
                <div className="phone-background-ellipse"></div>
                <div className="z-20 h-[20px] bg-black opacity-30 phone-shadow"></div>
                <img className="z-10 podium h-[7rem] w-[20rem]" src={Podium} alt="podium" />
              </div>
            </div>
          </div>
        </div>
        <div className="sm:hidden text-white">
          <div className="hero-content flex flex-col justify-between ">
            <div className="w-full float-left flex flex-col self-center p-3 sm:p-10 max-sm:items-center">
              <h1 className="line-height font-gimme-danger text-street-yellow text-[1.75rem] text-shadow">
                OWN YOUR STREET, GET STREET APP
              </h1>

              <p className="mt-4">
                Join Our Digital Hub: Where Neighbors Become Friends, Ideas
                Flourish, and <br /> Stories Unfold! Coming Soon!
              </p>
              <div className="hidden sm:flex flex flex-inline space-x-4 mt-8">
                <img src={PlayStoreLogo} alt="" />
                <img src={AppStoreLogo} alt="" />
              </div>
              <div className="sm:hidden w-[10rem] flex flex-col center space-y-4 mt-8">
                <img src={PlayStoreLogoMobile} alt="" />
                <img src={AppStoreLogoMobile} alt="" />
              </div>
            </div>
            <div className="w-full items-center flex flex-col phone-section sm:pr-3">
              <div className="z-10">
                <img className="phones" src={Phones} alt="phones" />
              </div>
              <div className="podium mt-4">
                <div className="phone-background-ellipse"></div>
                <div className="z-20 h-[20px] bg-black opacity-30 phone-shadow"></div>
                <img className="z-10 podium h-[7rem] w-[20rem]" src={Podium} alt="podium" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
