import React, { useState } from "react";

import axiosInstance from "../utils/axiosInstance";
import { toast, } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "../styles/toastifyStyles.css"

const JoinWaitlist = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const response = await axiosInstance.post(`waitlist/join`, {
                email,
                source: "web"
            });

            if (response.data.status === "success") {
                toast.success("Successfully joined waitlist.", {
                    position: toast.POSITION.TOP_CENTER
                });
                setEmail('');
                return;
            } else if (response.data.status === "failed") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

        } catch (error: any) {
            console.error({error})
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });

        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div id="waitlist-section" className="waitlist-section sm:w-[40%] md:w-[45%] lg:w-[35%] border flex items-center rounded-2xl border-white h-full flex-col p-6 ml-0">
                <div className="w-full line-height font-gimme-danger md:text-xl lg:text-3xl 2xl:text-4xl text-white">
                    GET EARLY ACCESS!!
                </div>
                <div className="w-full mt-4">
                    <form onSubmit={handleSubmit}>
                        <div className="relative">
                            <input
                                type="search"
                                id="search"
                                className="text-white rounded-3xl block w-full bg-input focus:outline-none focus:border-none focus:ring-white p-3"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required />
                            <button
                                type="submit"
                                disabled={loading}
                                className={`text-white absolute right-0 bottom-0 p-5 rounded-full ${loading ? 'loading' : 'bg-white'} `}
                            >
                                <svg
                                    width={15}
                                    height={9}
                                    viewBox="0 0 10 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.215 0.964844L9.25 3.99984L6.215 7.03484M0.75 3.99984L9.165 3.99984"
                                        stroke="#1B1B1B"
                                        strokeWidth="1.5"
                                        strokeMiterlimit={10}
                                        strokeLinecap="round"
                                        strokeLinejoin="round" />
                                </svg>
                            </button>

                        </div>
                    </form>
                </div>

            </div>
        </>
    );
};

export default JoinWaitlist;
